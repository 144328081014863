import pathOr from 'ramda/src/pathOr'
import map from 'ramda/src/map'
import memoizeWith from 'ramda/src/memoizeWith'

import moduleDescriptions from 'root/src/shared/descriptions/modules'
import fieldsPathDescriptionsSelector from 'root/src/client/logic/embedded/selectors/fieldsPathDescriptionsSelector'

export default memoizeWith(
	(state, props) => JSON.stringify(fieldsPathDescriptionsSelector(state, props)),
	(state, props) => map(path => pathOr('', [...path, 'endpointId'], moduleDescriptions), fieldsPathDescriptionsSelector(state, props)),
)
