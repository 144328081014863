/* eslint-disable no-case-declarations */
/* eslint-disable no-shadow */
/* eslint-disable max-len */
import moduleDescriptions from 'root/src/shared/descriptions/modules'
import changeEmbeddedFieldData from 'root/src/client/logic/embedded/actions/changeEmbeddedFieldData'
import allFieldsValuesSelector from 'root/src/client/logic/embedded/selectors/allFieldsValuesSelector'
import apiRequest from 'root/src/client/logic/api/thunks/apiRequest'
import addSortFilterParams from 'root/src/client/logic/header/actions/addSortFilterParams'
import setFirstPage from 'root/src/client/logic/list/actions/setFirstPage'
import setLoadingBlockList from 'root/src/client/logic/list/actions/setLoadingBlockList'
import clearProjectArray from 'root/src/client/logic/header/actions/clearProjectArray'
import fixedFilterSelector from 'root/src/client/logic/api/selectors/fixedFilterSelector'
import tabsFixedPayloadSelector from 'root/src/client/logic/tab/selectors/fixedPayloadSelector'
import currentRouteParamsRecordId from 'root/src/client/logic/route/selectors/currentRouteParamsRecordId'
import processSortFilter from 'root/src/client/util/processSortFilter'
import getEndpointIdFromModules from 'root/src/client/logic/route/util/getEndpointIdFromModules'
import processFixedFilter from 'root/src/client/logic/sortFilter/util/processFixedFilter'
import listModuleIdSelector from 'root/src/client/logic/header/selectors/listModuleIdSelector'
import pageItemLengthSelector from 'root/src/client/logic/list/selectors/pageItemLengthSelector'
import moduleDataFilterSelector from 'root/src/client/logic/api/selectors/moduleDataFilterSelector'
import allSelector from 'root/src/client/logic/list/selectors/allSelector'
import compose from 'ramda/src/compose'
import isEmpty from 'ramda/src/isEmpty'
import unnest from 'ramda/src/unnest'
import not from 'ramda/src/not'
import prop from 'ramda/src/prop'
import modifyPayloadSelector from 'root/src/client/logic/api/selectors/modifyPayloadSelector'
import pageItemsCountSelector from 'root/src/client/logic/list/selectors/pageItemsCountSelector'

export const setInputHof = changeEmbeddedFieldDataFn => (
	moduleId, fieldPath, value, endpointId, noCurrentPageData,
) => async (dispatch, getState) => {
	const listModuleId = listModuleIdSelector({}, { moduleId })
	dispatch(setFirstPage(listModuleId))
	dispatch(changeEmbeddedFieldDataFn(fieldPath, value))
	const state = getState()
	const modifyPayloadFn = modifyPayloadSelector(state, { moduleId })

	const sortFilters = allFieldsValuesSelector(state, { moduleId })
	const fixedFilter = fixedFilterSelector({}, { moduleId })

	const tabsFixedPayload = tabsFixedPayloadSelector(sortFilters)
	const pageItemLength = pageItemLengthSelector(state, { moduleId: listModuleId }) ?? pageItemsCountSelector(state, { moduleId: listModuleId })
	const setEndpointId = (getEndpointIdFromModules(state, moduleId) || endpointId)
	const startFilterValue = processFixedFilter(fixedFilter, currentRouteParamsRecordId(state))
	const all = allSelector(state, { moduleId: listModuleId })
	const { payloadFilter: moduleFilterPayload } = prop(moduleId, moduleDataFilterSelector(state)) || {}

	const processedPayload = processSortFilter({
		sortFilters,
		startValue: {
			...(tabsFixedPayload || {}),
			...(compose(not, isEmpty, unnest)(startFilterValue) ? { filter: startFilterValue } : {}),
			sortType: undefined,
			pageItemLength,
			all,
			...(noCurrentPageData ? {} : { currentPage: 1 }),
			...moduleFilterPayload,
		},
		state,
	})

	const requestPayload = modifyPayloadFn
		? modifyPayloadFn({ payload: processedPayload, state })
		: processedPayload

	if (!requestPayload) {
		return undefined
	}

	dispatch(addSortFilterParams(moduleId, requestPayload))
	dispatch(clearProjectArray())
	dispatch(setLoadingBlockList(true, moduleId))
	if (typeof endpointId === 'string') {
		return dispatch(apiRequest(
			setEndpointId, requestPayload, { selfClear: true }, listModuleId, moduleDescriptions,
		)).then(() => dispatch(setLoadingBlockList(false, moduleId)))
	}
	return setEndpointId.map(
		endpoint => dispatch(apiRequest(
			endpoint, requestPayload, { selfClear: true }, listModuleId, moduleDescriptions,
		)).then(() => dispatch(setLoadingBlockList(false, moduleId))),
	)
}

export default setInputHof(
	changeEmbeddedFieldData,
)
