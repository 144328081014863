import { memo } from 'react'
import map from 'ramda/src/map'
import addIndex from 'ramda/src/addIndex'
import { orNull } from 'root/src/shared/util/ramdaPlus'
import EmbeddedFieldWrapper from 'root/src/client/web/embedded/embeddedModules/EmbeddedFieldWrapper'
import { ltGtMediaQuery } from 'root/src/client/web/commonStyles'
import withStyles from '@material-ui/core/styles/withStyles'
import dynamic from 'next/dynamic'

const styles = {
	fields: {
		gap: '.5rem',
		display: 'flex',
		width: '100%',
		[ltGtMediaQuery]: {
			width: 'inherit',
		},
	},
	label: {
		color: 'white',
		fontSize: 12,
		marginBottom: 6,
	},
	item: {
		display: 'flex',
		flexDirection: 'column',
		alignSelf: 'flex-end',
		[ltGtMediaQuery]: {
			marginLeft: 5,
		},
	},
	fixedSmallWidth: {
		width: 126,
	},
	fixedLargeWidth: {
		width: 190,
	},
}

const inputTypesMap = {
	autoCompleteEmbedded: dynamic(() => import('root/src/client/web/embedded/embeddedModules/AutoCompleteEmbedded')),
	dropdownEmbedded: dynamic(() => import('root/src/client/web/embedded/embeddedModules/DropdownEmbedded')),
	variantFieldEmbedded: dynamic(() => import('root/src/client/web/embedded/embeddedModules/VariantFieldEmbedded')),
	navEmbedded: dynamic(() => import('root/src/client/web/embedded/embeddedModules/NavEmbedded')),
	checkboxEmbedded: dynamic(() => import('root/src/client/web/embedded/embeddedModules/CheckboxEmbedded')),
}

const EmbeddedFieldUnstyled = memo(({ classes, fields, ...moduleProps }) => (
	<div className={classes.fields}>
		{orNull(fields, addIndex(map)(({
			inputType, fieldId, fieldCaption, fixedSmallWidth, fixedLargeWidth, ...inputProps
		}, idx) => {
			const wrapperProps = {
				fieldId, classes, fieldCaption, key: fieldId,
			}

			const InputComponent = inputTypesMap[inputType]

			if (!InputComponent) {
				return null
			}

			return (
				<EmbeddedFieldWrapper {...wrapperProps} key={fieldId}>
					<InputComponent
						{...moduleProps}
						{...inputProps}
						fieldId={fieldId}
						fieldIndex={idx}
					/>
				</EmbeddedFieldWrapper>
			)
		}, fields))}
	</div>
))

export default memo(withStyles(styles, { name: 'MuiEmbeddedFields' })(EmbeddedFieldUnstyled))
