import React from 'react'
import { orNull } from 'root/src/shared/util/ramdaPlus'
import classNames from 'classnames'

export default ({ children, classes, fieldId, fieldCaption, fixedSmallWidth, fixedLargeWidth }) => (
	<div
		key={fieldId}
		className={classNames(classes.item,
			{ [classes.fixedSmallWidth]: fixedSmallWidth },
			{ [classes.fixedLargeWidth]: fixedLargeWidth })
		}
	>
		{orNull(fieldCaption,
			<div className={classes.label}>{fieldCaption}</div>)}
		{children}
	</div>
)
