import pathOr from 'ramda/src/pathOr'
import map from 'ramda/src/map'
import is from 'ramda/src/is'
import memoizeWith from 'ramda/src/memoizeWith'

import moduleDescriptions from 'root/src/shared/descriptions/modules'
import fieldsPathDescriptionsSelector from 'root/src/client/logic/embedded/selectors/fieldsPathDescriptionsSelector'

export default memoizeWith(
	(state, props) => map((path) => {
		const options = pathOr([], [...path, 'options'], moduleDescriptions)
		return is(Function, options) ? options(state).length : options.length
	}, fieldsPathDescriptionsSelector(state, props)),
	(state, props) => map((path) => {
		const options = pathOr([], [...path, 'options'], moduleDescriptions)
		return is(Function, options) ? options(state) : options
	}, fieldsPathDescriptionsSelector(state, props)),
)
