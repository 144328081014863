import prop from 'ramda/src/prop'
import map from 'ramda/src/map'
import reduce from 'ramda/src/reduce'
import propOr from 'ramda/src/propOr'
import filter from 'ramda/src/filter'
import propEq from 'ramda/src/propEq'
import unnest from 'ramda/src/unnest'
import equals from 'ramda/src/equals'
import { gameKey, assigneeKey } from 'root/src/shared/constants/keys'

export default ({
	sortFilters,
	startValue,
	state,
}) => reduce((acc, key) => {
	const item = prop(key, sortFilters)
	const prevFilter = propOr([], 'filter', acc)
	const filteredFilter = filter(filterItem => !propEq('param', key, filterItem), prevFilter)
	const newFilter = nextFilter => [[...unnest(filteredFilter), nextFilter]]
	const processItem = (payload) => {
		if (!payload) {
			return [[...unnest(acc.filter)]]
		}
		const { param, value } = payload
		return newFilter({ param, value })
	}
	// here two separate conditionals for key just for
	// readability - if else fits better for multiline code
	if (equals(key, 'filter') && state) {
		const { payload } = item
		const itemsProcessed = Array.isArray(payload)
			? unnest(map(processItem, payload)) : processItem(payload)
		return { ...acc, filter: itemsProcessed }
	}
	if (item.payload && state) {
		const { payload } = item
		return { ...acc, ...payload }
	}
	switch (key) {
	case 'sort':
		return { ...acc, sortType: prop('value', item) }
	case gameKey:
	case assigneeKey:
		/* eslint-disable no-case-declarations */
		const { id } = item
		const { platform } = sortFilters[key]
		return id ? {
			...acc,
			filter: newFilter({
				param: key,
				value: `${platform ? `${platform}|` : ''}${id}`,
			}),
		} : acc
	default:
		return acc
	}
}, startValue, filter(key => sortFilters[key], Object.keys(sortFilters)))
